var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-section",
    [
      _c("page-section-title", {
        attrs: {
          title: "お問い合わせ",
          subtitle:
            "Tuttiのツールとしてのご利用はもちろん、<br>\n                  カスタムでのラベリングシステムの開発や、データラベル収集の運用なども広く承っております。<br>\n                  まずはお気軽にご相談ください。",
        },
      }),
      _c("v-card", { staticClass: "ma-auto", attrs: { id: "form" } }, [
        _c(
          "div",
          { style: _vm.styles.form },
          [
            _c(
              "p",
              { staticClass: "form-header", style: _vm.styles.formHeader },
              [
                _vm._v("お名前 "),
                _c("span", { staticClass: "red--text" }, [_vm._v("*")]),
              ]
            ),
            _c("v-text-field", {
              style: _vm.styles.formTextInput,
              attrs: {
                dense: !_vm.$vuetify.breakpoint.forPC,
                outlined: "",
                placeholder: "情報 太郎",
              },
              model: {
                value: _vm.input.name,
                callback: function ($$v) {
                  _vm.$set(_vm.input, "name", $$v)
                },
                expression: "input.name",
              },
            }),
            _c(
              "p",
              { staticClass: "form-header", style: _vm.styles.formHeader },
              [
                _vm._v("貴社名 "),
                _c("span", { staticClass: "red--text" }, [_vm._v("*")]),
              ]
            ),
            _c("v-text-field", {
              style: _vm.styles.formTextInput,
              attrs: {
                dense: !_vm.$vuetify.breakpoint.forPC,
                outlined: "",
                placeholder: "株式会社知能フレームワーク研究所",
              },
              model: {
                value: _vm.input.affiliation,
                callback: function ($$v) {
                  _vm.$set(_vm.input, "affiliation", $$v)
                },
                expression: "input.affiliation",
              },
            }),
            _c(
              "p",
              { staticClass: "form-header", style: _vm.styles.formHeader },
              [
                _vm._v("メールアドレス "),
                _c("span", { staticClass: "red--text" }, [_vm._v("*")]),
              ]
            ),
            _c("v-text-field", {
              style: _vm.styles.formTextInput,
              attrs: {
                dense: !_vm.$vuetify.breakpoint.forPC,
                outlined: "",
                placeholder: "info@tutti.ai",
              },
              model: {
                value: _vm.input.email,
                callback: function ($$v) {
                  _vm.$set(_vm.input, "email", $$v)
                },
                expression: "input.email",
              },
            }),
            _c(
              "p",
              { staticClass: "form-header", style: _vm.styles.formHeader },
              [
                _vm._v("お問い合わせ内容 "),
                _c("span", { staticClass: "red--text" }, [_vm._v("*")]),
              ]
            ),
            _c("v-textarea", {
              style: _vm.styles.formTextInput,
              attrs: {
                dense: !_vm.$vuetify.breakpoint.forPC,
                outlined: "",
                placeholder:
                  "ご利用のお問い合わせ、製品に関してのご質問などのご記入をお願いいたします。",
                height: "250",
              },
              model: {
                value: _vm.input.message,
                callback: function ($$v) {
                  _vm.$set(_vm.input, "message", $$v)
                },
                expression: "input.message",
              },
            }),
            _c(
              "v-btn",
              {
                staticClass: "font-weight-bold mx-auto my-4",
                staticStyle: { display: "block" },
                attrs: {
                  rounded: "",
                  "x-large": "",
                  width: "200",
                  color: "black",
                  dark: _vm.isSubmittable,
                  disabled: !_vm.isSubmittable,
                },
                on: {
                  click: function ($event) {
                    return _vm.submitForm()
                  },
                },
              },
              [_vm._v("送信する")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }